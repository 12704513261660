<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2024 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-app>
    <notification-snackbars-wrapper />
    <v-main>
        <v-app-bar app flat class="v-bar--underline" color="background0">
            <router-link :to="{ name: 'entities' }" style="text-decoration: none; margin-top: 5px;">
                <img src="/static/unstruct-logo-new.png" width="55" height="55"   />
            </router-link>
            <v-spacer />
            <!--Search Field-->
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search IAOs ..."
                single-line
                hide-details
                clearable
                @click:clear="fetchEntities"
                @keyup.enter="fetchEntities"
            />
            <v-spacer />
            <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small color="error--text" v-bind="attrs" v-on="on">
                    <v-icon left class="mr-3">mdi-creation</v-icon>
                    Unstr
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item :to="{ name: 'entities' }">
                        <v-icon small left color="error--text">mdi-creation</v-icon>
                        <v-list-item-title>Entity-Centric Search</v-list-item-title>
                    </v-list-item>
                    <v-list-item :to="{ name: 'oldSchoolSearch' }">
                        <v-icon small left color="error--text">mdi-select-search</v-icon>
                        <v-list-item-title>Old School Search</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>

            </v-app-bar>
    <organization-banner />
    <incident-entities-tab
        :entities="entities"
        chartColor="blue-grey"
    />
    <v-btn
        v-if="entities.length >= numItems"
        @click="fetchEntities(false)"
        :loading="loading"
        :disabled="loading"
        color="primary"
        dark
        rounded
        class="mt-5 mb-5"
        elevation="2"
        block
    >
        <v-icon left>mdi-arrow-down</v-icon>
        Load More
    </v-btn>
    </v-main>
    </v-app>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import NotificationSnackbarsWrapper from "@/components/NotificationSnackbarsWrapper.vue"
import OrganizationBanner from "@/organization/OrganizationBanner.vue"
import IncidentEntitiesTab from "@/entity/IncidentEntitiesTab.vue"
import IncidentEntityCard from "@/entity/IncidentEntityCard.vue"
import EntityApi from "@/entity/api"
import SearchUtils from "@/search/utils"

export default {
    name: "EntityEntryPage",

    props: {
    project: {
        type: String,
        default: null,
    },
    },

    components: {
        OrganizationBanner,
        NotificationSnackbarsWrapper,
        IncidentEntitiesTab,
        IncidentEntityCard,
    },
    data() {
    return {
        loading: false,
        items: [],
        selected: {},
        entities: [],
        numItems: 10,
        currentPage: 1,
        itemsPerPageOptions: [10, 25, 50, 100],
        search: "",
    }
    },
    computed: {
    ...mapFields("incident", ["selected.id"]),
    },
    created() {
        this.fetchEntities()
    },
    methods: {
        fetchEntities(fromSearch = true) {
            this.loading = true
            let filterOptions = {
                q: this.search,
                itemsPerPage: this.numItems,
                page: fromSearch ? 1 : this.currentPage,
            }

            if (this.project) {
                filterOptions = {
                ...filterOptions,
                filters: {
                    project: [this.project],
                },
                        }
                filterOptions = SearchUtils.createParametersFromTableOptions({ ...filterOptions })
            }

            EntityApi.getAllByClass("other", filterOptions).then((response) => {
                if (fromSearch) {
                    this.entities = response.data.items
                    this.currentPage = 1
                } else {
                    this.entities.push(...response.data.items)
                    this.currentPage++
                }
                this.loading = false
            }).catch((error) => {
                console.error(error)
                this.loading = false
            })
        },
    },
}
</script>
